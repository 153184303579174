import React from 'react';
import {
  Image,
  isExperienceEditorActive,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import './carouselProduct.scss';
import { Carousel } from 'react-responsive-carousel';
import Lightbox from 'react-images';

class ProductImagesGalleryV1 extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.onClickThumbnail = this.onClickThumbnail.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      error: null,
      isLoaded: false,
      photoIndex: 0,
      isOpen: false,
      showMessage: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.props &&
      prevProps.props.sitecoreContext.route.fields !== this.props.sitecoreContext.route.fields
    ) {
      this.props.onProductChange(this.props.sitecoreContext.route.fields);
    }
  }

  handleChange(event) {
    this.setState({ isOpen: true, photoIndex: event });
  }
  onClose() {
    this.setState({ isOpen: false });
  }
  gotoNext() {
    this.setState({ photoIndex: this.state.photoIndex + 1 });
  }
  gotoPrevious() {
    this.setState({ photoIndex: this.state.photoIndex - 1 });
  }

  onClickThumbnail(event) {
    this.setState({ photoIndex: event });
  }

  render() {
    const product = this.props.sitecoreContext.route.fields;

    const images = [];
    const imageItems = Object.keys(product).filter((key) => key.includes('image'));
    imageItems.forEach(function (key) {
      if (product[key].value.src) {
        images.push(product[key]);
      }
    });

    const imageSrc = images.map((item) => item.value); // Images for the lightbox

    return (
      <div className="single-product-images">
        {isExperienceEditorActive() && (
          <div className="product-thumbnail">
            {images &&
              images.map((img, index) => {
                return <Image loading="lazy" key={index} field={img} />;
              })}
          </div>
        )}
        {!isExperienceEditorActive() && (
          <Carousel showStatus={false} showIndicators={false} onClickItem={this.handleChange}>
            {images &&
              images.map((img, index) => {
                return (
                  <div key={index}>
                    <img loading="lazy" src={img.value.src} alt="" />
                  </div>
                );
              })}
          </Carousel>
        )}
        <Lightbox
          images={imageSrc}
          isOpen={this.state.isOpen}
          onClickPrev={this.gotoPrevious}
          onClickNext={this.gotoNext}
          onClose={this.onClose}
          backdropClosesModal={true}
          currentImage={this.state.photoIndex}
          enableKeyboardInput={true}
          showImageCount={false}
          showThumbnails={true}
          onClickThumbnail={this.onClickThumbnail.bind(this)}
        />
      </div>
    );
  }
}

export default withSitecoreContext()(ProductImagesGalleryV1);
