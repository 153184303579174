import React from 'react';
import { Text, Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import './footerV2.scss';
import NavLinkAdv from '../../utils/NavLinkAdv';

class FooterV2 extends React.Component {
  render() {
    const { SocialLinks } = this.props.fields ? this.props.fields : '';
    const { MenuLinks } = this.props.fields ? this.props.fields : '';

    return (
      <footer className="main-ft">
        <div className="top-ft">
          <div className="col-md-8 col-xs-12">
            <p>
              <NavLinkAdv to={'/'} rel="noopener noreferrer">
                <Image
                  loading="lazy"
                  className="logo img_custom_full"
                  field={this.props.fields && this.props.fields.Logo}
                />
              </NavLinkAdv>
            </p>
            <ul className="sidebar_bot pl-0 pb-0">
              {this.props.fields.FooterMultilevel !== undefined &&
                this.props.fields.FooterMultilevel.map(
                  (footerMulti) =>
                    footerMulti.fields &&
                    footerMulti.fields.HeaderMenu.map((mainMenu, mainindex) => (
                      <li
                        id={`mainind_${mainindex}`}
                        className="first_li"
                        key={`foot-${mainindex}`}
                      >
                        {mainMenu.fields.Title &&
                          mainMenu.fields.InternalLink &&
                          mainMenu.fields.InternalLink.value.href !== '' && (
                            <NavLinkAdv
                              to={
                                mainMenu.fields.InternalLink &&
                                mainMenu.fields.InternalLink.value.href
                              }
                              className=" first_link"
                              rel="noopener noreferrer"
                            >
                              <Text field={mainMenu.fields.Title} />
                            </NavLinkAdv>
                          )}
                        {mainMenu.fields.SubMenuLinks.length > 0 && (
                          <ul id={`subind_${mainindex}`} className="sub-menu">
                            {mainMenu.fields.SubMenuLinks.map((subMenu, subindex) => (
                              <li key={`sub_${subindex}`} className="menu-item">
                                {subMenu.fields.InternalLink &&
                                  subMenu.fields.InternalLink.value.href !== '' && (
                                    <NavLinkAdv
                                      to={
                                        subMenu.fields.InternalLink &&
                                        subMenu.fields.InternalLink.value.href
                                      }
                                    >
                                      <Text field={subMenu.fields.Title} />
                                    </NavLinkAdv>
                                  )}
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))
                )}
            </ul>
          </div>
          <div className="col-md-4 col-xs-12">
            <p className="link_social mt-0">
              {SocialLinks &&
                SocialLinks.map((listItem, index) => (
                  <React.Fragment key={index}>
                    <NavLinkAdv
                      to={listItem.fields.InternalLink && listItem.fields.InternalLink.value.href}
                      target="_blank"
                      key={index}
                      rel="noopener noreferrer"
                    >
                      <Image
                        loading="lazy"
                        field={listItem.fields.Image}
                        className="img_custom_full"
                      />
                    </NavLinkAdv>
                  </React.Fragment>
                ))}
            </p>
            {this.props.fields && (
              <p className="mt-0">
                <Text className="link_stext" field={this.props.fields.Text} tag="strong" />
              </p>
            )}
            <p className="link_contact">
              {this.props.fields.TelephoneNumber.value.length > 0 && (
                <>
                  <i className="fas fa-phone" /> {i18next.t('ConsumerLabel')} :{' '}
                  <a href={this.props.fields.TelephoneNumber.value} target="_blank">
                    <Text field={this.props.fields.TelephoneNumber} />
                  </a>
                  <br />
                </>
              )}
              {this.props.fields.EmailAddress.value.length > 0 && (
                <>
                  {/* ICONE BEFORE DICTIONARY - <i className="far fa-envelope" aria-hidden="true"/>  */}
                  <a href={this.props.fields.EmailAddress.value} target="_blank">
                    <Text field={this.props.fields.EmailAddress} />
                  </a>
                </>
              )}
            </p>
          </div>
        </div>

        <div className="bottom-ft">
          <div>
            <p>
              <span className="">{i18next.t('CopyrightLabel')}</span>
              {MenuLinks &&
                MenuLinks.map((listItem, index) => (
                  <React.Fragment key={index}>
                    <NavLinkAdv
                      to={listItem.fields.InternalLink && listItem.fields.InternalLink.value.href}
                      key={index}
                      className=""
                      rel="noopener noreferrer"
                    >
                      <Text field={listItem.fields.Title} />
                    </NavLinkAdv>
                  </React.Fragment>
                ))}
              <a id="ot-sdk-btn" className={'ot-sdk-show-settings'}>
                {i18next.t('CookieConfigurationLabel')}
              </a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}

export default withSitecoreContext()(FooterV2);
