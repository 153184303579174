import React from 'react';
import { Text, RichText, Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../utils/NavLinkAdv';
import './blockImgRight.scss';
import i18next from 'i18next';

const BlockImageRight = (props) => {
  const datasource = props.fields || {};
  let designVariant = props.fields.designVariant && props.fields.designVariant.value;

  let classesBlock;

  if (datasource.Image.value && designVariant.includes('style-paper')) {
    classesBlock = {
      backgroundImage: `url(` + props.fields.Image.value.src + `)`,
    };
  }

  if (datasource !== undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
    return (
      <div className={'section blockImgRight ' + designVariant + ''}>
        {' '}
        {/* Add this : style={classesBlock} to have background imgage (need to do the css) */}
        <div className="row" style={classesBlock}>
          <div className="block-left">
            <Text field={props.fields.Title} tag="h2" className="title" />
            <RichText tag="p" field={props.fields.Description} className={'content'} />
            {props.fields.Link.value.href !== '' && (
              <NavLinkAdv to={props.fields.Link.value.href} className="link">
                <span>{props.fields.Link.value.title}</span>
                <i className="fa fa-angle-right" />
              </NavLinkAdv>
            )}
          </div>
          <div className="block-right">
            <Image loading="lazy" field={props.fields.Image} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="component-no-datasource">
        {i18next.t('no-datasource') + ' ' + props.rendering.componentName + ' ' + t('component')}
      </div>
    );
  }
};

export default withSitecoreContext()(BlockImageRight);
