import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import './ErrorContent.scss';

class ErrorContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    window.location.href = '/searchResults?search=' + this.state.value;
    event.preventDefault();
  }

  render() {
    const datasource = this.props.fields || {};
    if (datasource != undefined && Object.getOwnPropertyNames(datasource).length >= 1) {
      const designVariant =
        this.props.fields.designVariant === undefined
          ? ''
          : this.props.fields.designVariant && this.props.fields.designVariant.value;
      return (
        <div className={`not-found-wrapper ${designVariant}`}>
          <span className="not-found-title">
            <Text field={this.props.fields.Title} />
          </span>
          <span className="not-found-subtitle">
            <Text field={this.props.fields.SubTitle} />
          </span>
          <div className="widget widget_search">
            <p>
              <Text field={this.props.fields.ErrorMessage} />
            </p>
            <div className="search-wrapper">
              <form className="searchform" onSubmit={this.handleSubmit}>
                <label for="s" class="sr-only">
                  Search
                </label>
                <input
                  type="search"
                  className="form-control"
                  name="s"
                  value={this.state.value}
                  autocomplete="off"
                  placeholder="Search..."
                  onChange={this.handleChange}
                />
                <input
                  type="submit"
                  id="searchsubmit"
                  className="hidden"
                  name="submit"
                  value="Search"
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="component-no-datasource">
          {i18next.t('no-datasource') +
            ' ' +
            this.props.rendering.componentName +
            ' ' +
            i18next.t('component')}
        </div>
      );
    }
  }
}

export default ErrorContent;
