import React from 'react';

class InstagramImageItem extends React.Component {

  render() {
    let imageUrl = this.props.item && this.props.item.imageMediaUrl && this.props.item.imageMediaUrl.value
    return (
        <div className={`instagram-image-item sameWidth`}>
            <a href={this.props.item && this.props.item.imagePermalink && this.props.item.imagePermalink.value} target="_blank" rel="noopener noreferrer">
              {/* <img src={this.props.item.imageMediaUrl.value}/> */}
              
              <div className="image" style={{backgroundImage: 'url('+ imageUrl+ ')'}}></div>
            </a>
        </div>
    )
  }
}

export default InstagramImageItem;
