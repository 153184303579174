import React from 'react';
import ProductGridItem from '../ProductGridItem';
import ReactComponent from "../../ReactComponent";
import { withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import { DotLoader } from 'react-spinners';
import NavLinkAdv from "../../utils/NavLinkAdv";

import { Query } from "react-apollo";
import {loader as gqlLoader} from "graphql.macro";
import Pagination from "../../utils/Pagination";
import { observeProductFinder } from "../Tagging";



const FilteredProductListQuery = gqlLoader('./filteredProductListQuery.graphql');

class FilteredProductList extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      filters: [
        { name : "_templatename", value : "ProductRoute" }
      ],
      view: "grid",
      itemsPerPage: "15",
      range: ["*","*"],
      sorting: [],
      keyword: "",
      after: "0",
      currentPage: 1
    };
    this.handleView = this.handleView.bind(this);
    this.handleItemsPerPage = this.handleItemsPerPage.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
    this.resolveSorting = this.resolveSorting.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let value = localStorage.getItem('ProductListSorting') || "default";
    let sorting = this.resolveSorting(value);

    const url = window.location.href;

    if (url.includes('?categoryId=') || url.includes('?brandId=') || url.includes('?tagId=') || url.includes('?ingredientId=') || url.includes('?advantagesId=')) {
      let templateName;
      if(url.includes('?categoryId=')){
        templateName = "productcategories";
      }else if(url.includes('?brandId=')){
        templateName = "brands";
      }else if(url.includes('?tagId=')){
        templateName = "tag";
      }else if(url.includes('?ingredientId=')){
        templateName = "productingredients";
      }else if(url.includes('?advantagesId=')){
        templateName = "productadvantages";
      }

      this.ifChooseAnotherCategory(templateName);

    }else if(url.includes('?search=')) {
      const searchUrl = url.substring(url.indexOf("=") + 1);
      this.state.keyword = searchUrl.toLowerCase();
    }

    this.setState({
      view: localStorage.getItem('ProductListDisplayType') || "list",
      sorting: sorting
    });

    if(this.props.fields !== undefined && this.props.fields.Image.value.src && this.props.fields.Title.value){
      this.setState({itemsPerPage: 14})
    }
  }

  ifChooseAnotherCategory(templateName) {
    const urlClean = window.location.href.substring(window.location.href.indexOf("=") + 1);
    if (urlClean.includes('#')) {
      let newUrl = urlClean.replace('#', '');

      this.state.filters.push({ name : templateName, value : newUrl.toLowerCase() });
    }else {
      this.state.filters.push({ name : templateName, value : urlClean.toLowerCase() });
    }
  }

  handleChange(data){
  /* GTM Tagging */
  observeProductFinder(this.props.sitecoreContext)
    this.setState({
      after: data.after.toString(),
      currentPage: data.currentPage
    });

  }

  resolveSorting(value) {
    let sorting = [];
    switch(value) {
      case "default":
        sorting = ["*", "*"];
        break;
      case "ratingUp":
        sorting = ["rating", "ASC"];
        break;
      case "ratingDown":
        sorting = ["rating", "DESC"];
        break;
      case "newnessUp":
        sorting = ["__smallupdateddate", "ASC"];
        break;
      case "newnessDown":
        sorting = ["__smallupdateddate", "DESC"];
        break;
      case "priceUp":
        sorting = ["saleprice", "ASC"];
        break;
      case "priceDown":
        sorting = ["saleprice", "DESC"];
        break;
      default:
        sorting = ["*", "*"];
        break;
    }
    return sorting;
  }

  handleView() {
    let view = "";
    if (this.state.view === "list") {
      view = "grid";
      localStorage.setItem('ProductListDisplayType', "grid");
    }
    else if (this.state.view === "grid") {
      view = "list";
      localStorage.setItem('ProductListDisplayType', "list");
    }
    if(window.location.href.indexOf("=") !== -1) {
      this.setState({
        view:view,
        keyword: window.location.href.substring(window.location.href.indexOf("=") + 1)
      });
    }else {
      this.setState({
        view:view
      });
    }
  }

  handleItemsPerPage(e) {
    localStorage.setItem('ProductListItemsPerPage', e.currentTarget.value);
    this.setState({
      itemsPerPage: e.currentTarget.value,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Before and After
    // prevProps and this.props
    if(prevProps.filters !== this.props.filters) {
      /* GTM Tagging */
      observeProductFinder(this.props.sitecoreContext)
      let all__items = [{ name : "_templatename", value : "ProductRoute" }];
      if(this.props.filters.length !== 0 && this.props.filters[0] !== undefined){
        this.props.filters[0].items.map((item) => {
          if (item.item.__typename === "ProductCategoryTemplate") {
            all__items.push({ name : "productcategories", value : item.item.id.toLowerCase() });
          }
          if (item.item.__typename === "ProductTagTemplate") {
            all__items.push({ name : "tag", value : item.item.id.toLowerCase() });
          }
          if (item.item.__typename === "ProductBrandTemplate") {
            all__items.push({ name : "brands", value : item.item.id.toLowerCase() });
          }
          if (item.item.__typename === "ProductIngredientTemplate") {
            all__items.push({ name : "productingredients", value : item.item.id.toLowerCase() });
          }
          if (item.item.__typename === "ProductAdvantageTemplate") {
            all__items.push({ name : "productadvantages", value : item.item.id.toLowerCase() });
          }
        });
      }
      this.setState({
        filters: all__items
      });
    }
    if (prevProps.rangeFilter !== this.props.rangeFilter) {
      this.setState({
        range: [prevProps.rangeFilter[0].toString(), prevProps.rangeFilter[1].toString()]
      });
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(this.props.keyword === "" || this.props.keyword === undefined) {
      this.setState({
        keyword: ""
      });
    }else{
      this.setState({
        keyword: nextProps.keyword
      });
    }
    if (nextProps.rangeFilter !== this.props.rangeFilter) {
      this.setState({
        range: [nextProps.rangeFilter[0].toString(), nextProps.rangeFilter[1].toString()]
      });
    }
  }


  handleSorting(e) {
    localStorage.setItem('ProductListSorting', e.currentTarget.value);
    let sorting = this.resolveSorting(e.currentTarget.value);
    this.setState({
      sorting: sorting,
    });
  }

  SortByBackofficeSortOrder(x, y) {
    return parseInt(x.item.SortValue.value) ===
      parseInt(y.item.SortValue.value)
      ? 0
      : parseInt(x.item.SortValue.value) > parseInt(y.item.SortValue.value)
      ? 1
      : -1;
  }

  render() {

    return(
      <React.Fragment>
        <Query query={FilteredProductListQuery} variables={{
          indexname: this.indexName,
          rootPath: "/sitecore/content/" + this.appName + "/",
          first: parseInt(this.state.itemsPerPage),
          after: this.state.after,
          keyword: this.state.keyword || this.props.keyword ? this.state.keyword || this.props.keyword : "",
          rangeFrom: this.state.range[0],
          rangeTo: this.state.range[1],
          sortByName: "globalRanking",
          sortByValue: "ASC",
          fieldsEqual: Object.values(this.state.filters)
        }}>
          {({loading, error, data}) => {
            if (loading)
              return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <DotLoader

                        sizeUnit={"px"}
                        size={25}
                        color={'#003D0E'}
                        loading={!this.state.isLoaded}
                    />
                  </div>
              );

            if (error)
              return (
                  <div>Error: {error.message}</div>
              );

            let totalItems = data.search.results.totalCount;

            return (

              <div className="FilterProductList">
                  
                  <div className="shop-toolbar">
                  </div>
                  <div className="shop-loop-wrap">
                    <div className="shop-loop grid">
                      <ul className="products columns-3">  
                      {/* PUSH SEO */}
                      {this.props.fields !== undefined && this.props.fields.Image.value.src && this.props.fields.Title.value &&
                          <li className="push-seo product" >
                            <NavLinkAdv to={this.props.fields.Url.value.href}>
                              <div className="img-ctn" style={{backgroundImage: `url(`+ this.props.fields.Image.value.src +`)`}}></div>
                              <div className="content-ctn">
                                <h3 className="title">{this.props.fields.Title.value}</h3>
                                <div className="text"><RichText field={this.props.fields.Text} /></div>
                                {this.props.fields.Url && this.props.fields.Url.value.linktype === "internal" && 
                                  <div className="link" >{this.props.fields.Url.value.text}</div>
                                }
                                {this.props.fields.Url && this.props.fields.Url.value.linktype === "external" && 
                                  <a href={this.props.fields.Url.value.href} className="link">{this.props.fields.Url.value.text}</a>
                                }
                              </div>
                            
                            </NavLinkAdv>
                          </li>
                          }                       
                        {data && data.search.results.items.map((product, index) => (
                            <li key={index} className="product">
                              <ProductGridItem index={index} product={product} listView={this.state.view === "list" ? true : false} />
                            </li>
                        ))}
                                               
                      </ul>
                      
                    </div>
                    
                  </div>
                  <nav className="commerce-pagination col-md-12">
                        <Pagination totalItems={totalItems}
                                    nbOfItemsToShow={parseInt(this.state.itemsPerPage)}
                                    onChange={this.handleChange}
                                    currentPage={this.state.currentPage}/>
                      </nav>
                </div>
            )
          }}
        </Query>
        <Query query={FilteredProductListQuery} variables={{
          indexname: this.indexName,
          rootPath: "/sitecore/content/" + this.appName + "/",
          first: 10000,
          after: 0,
          keyword: this.state.keyword || this.props.keyword ? this.state.keyword || this.props.keyword : "",
          rangeFrom: this.state.range[0],
          rangeTo: this.state.range[1],
          sortByName: this.state.sorting[0] !== undefined ? this.state.sorting[0] : "*",
          sortByValue:  this.state.sorting[1] !== undefined ? this.state.sorting[1] : "*",
          fieldsEqual: Object.values(this.state.filters)
        }}>{({loading, error, data}) => {
          if (loading)
            return (
                <div></div>
            );

          if (error)
            return (
                <div>Error: {error.message}</div>
            );

            
            return (
              <div style={{display: 'none'}}>
              {data.search.results.items.map((product, index) => {
                return(
                  <a key={index} href={product.item.url}>{product.item.title.value}</a>
                )
              })}
            </div>

          )
          }}
        </Query>

      </React.Fragment>
        
    );
  }
}

export default withSitecoreContext()(FilteredProductList);
